import { CookiesToken } from '@/types/CookiesToken'
import {
    getBrowserLanguage,
    getCookieValue,
    getDeviceOrientation,
    isMobile,
    setCookieValue
} from '@/utils'
import { AppState, State } from 'vue'
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AppActions, AppMutation } from '../types'
import { pingServer } from '@/api'
import { ungzip } from 'pako'

const state: AppState = {
    device: { display: isMobile(), orientation: getDeviceOrientation() },
    showLoading: false,
    canPlaySound: parseInt(getCookieValue(CookiesToken.SOUND) || '1'),
    isUserInteracted: -1,
    lang: getCookieValue(CookiesToken.LANG) ?? getBrowserLanguage(),
    isfullScreen: false,
    video: parseInt(getCookieValue(CookiesToken.VIDEO) || '1'),
    musicVolume: parseInt(getCookieValue(CookiesToken.MUSIC_VOLUME) || '1'),
    soundVolume: parseInt(getCookieValue(CookiesToken.SOUND_VOLUME) || '1'),
    currentChipIndex: parseInt(
        getCookieValue(CookiesToken.SELECTED_CHIP) || '0'
    ),
    line: parseInt(getCookieValue(CookiesToken.LINE) ?? '0'),
    lineStr: getCookieValue(CookiesToken.LINE_STR)?.b64decode() ?? ''
}

const mutations: MutationTree<AppState> = {
    [AppMutation.SET_ORIENTATION](state, payload: 'landscape' | 'portrait') {
        state.device.orientation = payload
    },
    [AppMutation.HIDE_LOADING](state) {
        state.showLoading = false
    },
    [AppMutation.SHOW_LOADING](state) {
        state.showLoading = true
    },
    [AppMutation.SET_MUSIC_VOLUME](state, payload: number) {
        state.musicVolume = payload
        setCookieValue(CookiesToken.MUSIC_VOLUME, String(payload))
    },
    [AppMutation.SET_SOUND](state, payload: boolean) {
        if (payload) state.canPlaySound = 1
        else state.canPlaySound = 0
        setCookieValue(CookiesToken.SOUND, String(payload))
    },
    [AppMutation.SET_USER_INTERACTION](state) {
        state.isUserInteracted = 1
    },
    [AppMutation.SET_LANG](state, payload: string) {
        if (state.lang !== payload) {
            state.lang = payload
            setCookieValue(CookiesToken.LANG, payload)
        }
    },
    [AppMutation.SET_FULL_SCREEN](state, payload: boolean) {
        state.isfullScreen = payload
    },
    [AppMutation.SET_VIDEO](state, payload: boolean) {
        if (payload) state.video = 1
        else state.video = 0
        setCookieValue(CookiesToken.VIDEO, String(state.video))
    },
    [AppMutation.SET_SELECTED_CHIP](state, payload: number) {
        state.currentChipIndex = payload
        setCookieValue(CookiesToken.SELECTED_CHIP, String(payload))
    },
    [AppMutation.APP_SET_LINE](state, payload: number) {
        state.line = payload
        setCookieValue(CookiesToken.LINE, String(payload))
    },
    [AppMutation.APP_SET_LINE_STR](state, payload: string) {
        state.lineStr = payload
        setCookieValue(CookiesToken.LINE_STR, payload.b64encode())
    }
}

const getters: GetterTree<AppState, State> = {
    isMobile(state) {
        return state.device.display
    },
    deviceOrientation: (state) => state.device.orientation,
    showLoading(state) {
        return state.showLoading
    },
    canPlaySound(state) {
        return state.canPlaySound === 1
    },
    isUserInteracted(state) {
        return state.isUserInteracted !== -1
    },
    lang(state) {
        return state.lang
    },
    isfullScreen(state) {
        return state.isfullScreen
    },
    soundEffect(state) {
        return state.canPlaySound
    },
    videoIsOn(state) {
        return state.video === 1
    },
    musicVolume: (state) => state.musicVolume,
    soundVolume: (state) => state.soundVolume,
    currentChipIndex: (state) => state.currentChipIndex,
    serverLine(state): number {
        return state.line
    },
    serverLineStr(state): string {
        return state.lineStr
    }
}

const actions: ActionTree<AppState, State> = {
    [AppActions.PING_SERVER](
        { ...args },
        params: { line: number; url: string }
    ): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            const { line, url } = params
            pingServer(line, url)
                .then((response) => {
                    const compressedData = new Uint8Array(response.data)
                    const unzippedData = ungzip(compressedData, {
                        to: 'string'
                    })
                    resolve(unzippedData)
                })
                .catch(() => reject())
        })
    }
}

export const app: Module<AppState, State> = {
    state,
    mutations,
    actions,
    getters
}
