/**
 * get the device orientation
 * @returns string portrait | landscape
 */

import { EventBuss, Lang } from '@/types/global'
import {
    DialogBoxName,
    MessageBoxActionType,
    MessageBoxEvent
} from '@/types/MessageBox'

export function getDeviceOrientation(): 'portrait' | 'landscape' {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    if ((window.screen as any).orientation) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (window.screen as any).orientation.type.includes('landscape')
            ? 'landscape'
            : 'portrait'
    }

    // iOS/safari
    // get device orientation in degrees
    const orientation = window.orientation

    // determine whether device is in portrait or landscape
    if (orientation === 0 || orientation === 180) {
        return 'portrait'
    } else if (orientation === 90 || orientation === -90) {
        return 'landscape'
    } else {
        // check if screen orientation api is available
        if (screen.orientation && screen.orientation.type) {
            const orientation = screen.orientation.type
            // determine whether device is in portrait or landscape
            if (
                orientation === 'portrait-primary' ||
                orientation === 'portrait-secondary'
            ) {
                // console.log('Device is in portrait orientation. f test')
                return 'portrait'
            } else if (
                orientation === 'landscape-primary' ||
                orientation === 'landscape-secondary'
            ) {
                // console.log('Device is in landscape orientation. f test')
                return 'landscape'
            }
        }

        // check device orientation using window.matchMedia()
        const isPortrait = window.matchMedia('(orientation: portrait)').matches
        // determine whether device is in portrait or landscape
        if (isPortrait) {
            // console.log('Device is in portrait orientation.')
            return 'portrait'
        } else {
            // console.log('Device is in landscape orientation.')
            return 'landscape'
        }
    }
}

/**
 * Check if It is A mobile device or not
 * @returns bolean
 */
export function isMobile(): boolean {
    if (
        navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
        )
    ) {
        return true
    }
    return false
}

/**
 * return browser language
 * @returns browser language
 */
export function getBrowserLanguage(): string {
    // const lang = (navigator.language || 'zh-cn').split('-')[0]

    // if (Lang.indexOf(lang) === -1) {
    //     return 'zh-cn'
    // }

    // return lang

    return 'cn'
}

/**
 * generate random number between min and max number
 * @param min smallest posible number
 * @param max maximum pasible number
 */
export function generateRandomNumber(min: number, max: number): number {
    return Math.floor(Math.random() * (max - min + 1)) + min
}

export function parseBool(strValue: string): boolean {
    return !!JSON.parse(String(strValue).toLowerCase())
}

/**
 * Abrevate number into short digits
 * @param n
 * @returns
 */
export function abbreviateNumber(n: number): string {
    if (n < 1e3) return n.toString()
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + 'K'
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + 'M'
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + 'B'
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + 'T'

    return n.toString()
}

/**
 * Get Item In LocalStorage
 * @param _key
 * @returns
 */
export function getCookieValue(_key: string) {
    return window.sessionStorage.getItem(_key)
}
/**
 * Set Item in LocalStorage
 * @param _key
 * @param _value
 */
export function setCookieValue(_key: string, _value: string) {
    window.sessionStorage.setItem(_key, _value)
}

/**
 * Convert Date to String format yyyy/mm/dd hh:mm 24 hrs
 * @param date
 * @returns
 *
 */
export function formatDate(date: Date): string {
    const year = String(date.getFullYear())
    const month = String(date.getMonth() + 1).padStart(2, '0')
    const day = String(date.getDate()).padStart(2, '0')
    const hour = String(date.getHours()).padStart(2, '0')
    const minute = String(date.getMinutes()).padStart(2, '0')
    const seconds = String(date.getSeconds()).padStart(2, '0')

    const mDate = [year, month, day]
    const mTime = [hour, minute, seconds]

    return `${mDate.join('/')} ${mTime.join(':')} `
}

export function isIEBrowser() {
    const userAgent = navigator.userAgent
    return (
        userAgent.indexOf('MSIE') !== -1 ||
        userAgent.indexOf('Trident/') !== -1 ||
        userAgent.indexOf('IE') !== -1
    )
}

export function isClicked(e: MouseEvent, targetAlpha = 0): boolean {
    const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()

    const x = e.clientX - rect.left
    const y = e.clientY - rect.top

    const canvas = document.createElement('canvas')
    const context = canvas.getContext('2d')
    canvas.height = rect.height
    canvas.width = rect.width

    const ev = window.getComputedStyle(e.currentTarget as HTMLElement)
    const src = ev
        .getPropertyValue('background-image')
        .replace(/url\(['"]?(.*?)['"]?\)/i, '$1')

    const img = document.createElement('img')
    img.src = src

    // e.currentTarget as HTMLImageElement

    // Draw the div content onto the canvas

    context?.drawImage(img, 0, 0, canvas.width, canvas.height)

    // Get the pixel data at the clicked position
    const pixelData = context?.getImageData(x, y, 1, 1).data

    // Extract the alpha value (opacity) from the pixel data
    if (pixelData) {
        const alpha = pixelData[3] / 255

        // console.log(alpha)
        // if (alpha < 0.5) {
        //     return false
        // }

        if (targetAlpha !== 0) {
            return alpha >= targetAlpha
        }

        if (alpha < 0.3) {
            return false
        }
    }

    return true
}

/**
 * Check if its an Ios Browser
 * @returns boolean
 */
export function isIosMobile() {
    return /iPhone|iPad/.test(navigator.userAgent)
}

/**
 * Check String if valid number value
 * @param inputStr
 * @returns
 */
export function isValidNumberAmount(inputStr: string): boolean {
    const pattern = /^\d+(\.\d{1,2})?$/

    const result = pattern.test(inputStr)

    return result
}

export function isIpadPro(): boolean {
    const userAgent = navigator.userAgent

    // Check if it's an iPad or iPadOS (reporting as Macintosh)
    const isIpad =
        /iPad|Macintosh/i.test(userAgent) && (navigator.maxTouchPoints || 0) > 0

    if (!isIpad) return false

    // Normalize screen dimensions for device pixel ratio
    const screenWidth =
        Math.max(window.screen.width, window.screen.height) *
        window.devicePixelRatio
    const screenHeight =
        Math.min(window.screen.width, window.screen.height) *
        window.devicePixelRatio

    // Known dimensions of iPad Pro models
    const ipadProScreenSizes = [
        { width: 1024, height: 1366 },
        { width: 2048, height: 2732 }, // 12.9-inch iPad Pro
        { width: 1668, height: 2388 }, // 11-inch iPad Pro
        { width: 1640, height: 2360 } // 10.9-inch iPad Pro / Air
    ]

    // Check if the current screen matches any iPad Pro dimensions
    const result = ipadProScreenSizes.some(
        (size) =>
            (size.width === screenWidth && size.height === screenHeight) ||
            (size.width === screenHeight && size.height === screenWidth) // Handle orientation
    )

    return result
}
